<template>
  <div>
    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'trader.home' }">Home</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'trader.academy' }"
                  >Academy</router-link
                >
              </li>
              <li><span>Payment</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="uk-section uk-padding-remove-vertical uk-margin-remove-vertical"
      v-if="! loading"
    >
      <div class="uk-container uk-margin-top uk-padding">
        <h2>Pay For Academy Week</h2>
        <hr>
        <div
          class="uk-child-width-1-1 uk-child-width-1-3@m uk-grid"
          data-uk-grid=""
        >
          <div class="uk-first-column uk-background-default uk-margin-right uk-padding">
            <h4>Payment Details</h4>
            <table class="uk-table uk-table-divider">
              <tbody>
                <tr>
                  <td>Section</td>
                  <td>Section {{ plan.section.sort }}. {{ plan.section.title }}</td>
                </tr>
                <tr>
                  <td>Course</td>
                  <td>Course {{ plan.course.sort }}. {{ plan.course.title }}</td>
                </tr>
                <tr>
                  <td>Week</td>
                  <td>Week {{ plan.week.sort }}. {{ plan.week.title }}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>$ {{ plan.cost }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="uk-background-default uk-padding uk-width-1-2@m">
            <div class="uk-row-first">
              <payment
                type="academy"
                :id="$route.params.id"
                @received="paymentReceived"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      plan: {},
      subscription: null,
      loading: true
    };
  },

  methods: {
    fetch() {
      this.$loader.start("full");
      this.loadeing = true

      this.$axios
        .get(`/api/v1/trader/academy/pay/${this.$route.params.id}`)
        .then((response) => {
          this.$loader.stop();

          if(response.data.plan.paid) {
            this.$router.push({ name: 'trader.academy.load', params: { id: this.$route.params.id }})
          } else {
            this.plan = response.data.plan;
            this.loading = false
          }
        });
    },

    paymentReceived() {
      window.location.reload("true");
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
